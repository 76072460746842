// toggle mobile menu
const toggleMenuBtn = document.querySelector(".navbar-toggle .toggle");
const mobileNav = document.querySelector(".mobile-nav");
const body = document.querySelector("body");
const overlay = document.querySelector(".overlay");

toggleMenuBtn.addEventListener("click", toggleMobileMenu);
overlay.addEventListener("click", toggleMobileMenu);

function toggleMobileMenu() {
  toggleMenuBtn.classList.toggle("is-active");
  mobileNav.classList.toggle("is-active");
  body.classList.toggle("lock-scroll");
  overlay.classList.toggle("is-active");
}

// ================= faq (accordion) section ===============
const fragranceAccordionBtns = document.querySelectorAll(
  ".fragranceCompounds .accordion"
);
const flavorsAccordionBtns = document.querySelectorAll(".flavors .accordion");

const fragranceAllImage = document.querySelectorAll(
  ".fragranceCompounds .picture-item picture"
);
const flavorsAllImage = document.querySelectorAll(
  ".flavors .picture-item picture"
);

function hasImageClass(imageElement, id) {
  const classNames = imageElement.className.split(" ");

  for (const className of classNames) {
    if (className.includes(id)) {
      return true;
    }
  }

  return false;
}

function showMatchingImage(accordionBtns, imageElements, clickedId) {
  imageElements.forEach((image) => {
    if (hasImageClass(image, clickedId)) {
      image.style.display = "block";
    } else {
      image.style.display = "none";
    }
  });

  accordionBtns.forEach((accordion) => {
    const content = accordion.nextElementSibling;
    if (accordion.dataset.id === clickedId) {
      accordion.classList.toggle("is-open");
      content.style.maxHeight = accordion.classList.contains("is-open")
        ? content.scrollHeight + "px"
        : null;
    } else {
      accordion.classList.remove("is-open");
      content.style.maxHeight = null;
    }
  });
}

fragranceAccordionBtns.forEach((accordion) => {
  accordion.addEventListener("click", function () {
    const clickedId = this.dataset.id;
    showMatchingImage(fragranceAccordionBtns, fragranceAllImage, clickedId);
  });
});

flavorsAccordionBtns.forEach((accordion) => {
  accordion.addEventListener("click", function () {
    const clickedId = this.dataset.id;
    showMatchingImage(flavorsAccordionBtns, flavorsAllImage, clickedId);
  });
});

// input field with flag
$(function () {
  var code = ""; // Assigning value from model.
  $("#mobile").val(code);
  $("#mobile").intlTelInput({
    autoHideDialCode: true,
    autoPlaceholder: "ON",
    dropdownContainer: document.body,
    formatOnDisplay: true,
    // hiddenInput: "full_number",
    initialCountry: "in",
    //  nationalMode: true,
    placeholderNumberType: "MOBILE",
    preferredCountries: ["us", "gb", "in"],
    separateDialCode: true,
  });
  $("#btn-submit").on("click", function () {
    var code = $("#mobile").intlTelInput("getSelectedCountryData").dialCode;
    var phoneNumber = $("#mobile").val();
    document.getElementById("code").innerHTML = code;
    document.getElementById("mobile-number").innerHTML = phoneNumber;
  });
});

// =========== animation script area ============
const tl = gsap.timeline();

// hero area animation
window.addEventListener("load", () => {
  tl.from(".hero-animate-fade-up", 0.75, {
    opacity: 0,
    y: 50,
    duration: 0.65,
    ease: Power4.easeOut,
  });

  tl.fromTo(
    ".hero-animate-fade",
    {
      opacity: 0,
    },
    {
      opacity: 1,
      stagger: 0.1,
      delay: -1,
      ease: Expo.easeInOut,
    }
  );

  tl.fromTo(
    ".hero-social-media",
    {
      opacity: 0,
      x: -30,
    },
    {
      opacity: 1,
      stagger: 0.1,
      delay: -0.25,
      x: 0,
      ease: Expo.easeInOut,
    }
  );
});

//  animation fade in
const fadeIn = gsap.utils.toArray(".animate-fade-in");
fadeIn.forEach((mainContent, i) => {
  const anim = gsap.fromTo(
    mainContent,
    { opacity: 0 },
    { duration: 0.45, opacity: 1, stagger: 0.2 }
  );
  ScrollTrigger.create({
    trigger: mainContent,
    animation: anim,
    toggleActions: "play",
    once: true,
    delay: -0.75,
    ease: Expo.easeInOut,
  });
});

// animate fade in up
const textContainers = gsap.utils.toArray(".animate-fade-in-up");
textContainers.forEach((item, i) => {
  const anim = gsap.fromTo(
    item,
    { opacity: 0, y: 50 },
    { duration: 1, opacity: 1, y: 0 }
  );
  ScrollTrigger.create({
    trigger: item,
    animation: anim,
    toggleActions: "play",
    once: true,
    duration: 1,
    stagger: 0.1,
    ease: Power4.easeOut,
  });
});

$(document).ready(function () {
  $(function () {
    // Setup form validation on the #register-form element
    $("form[name='footer-form']").validate({
      rules: {
        name: "required",
        email: {
          //email is corresponding input name
          required: true,
          email: true,
        },
        message: "required",
      },
      // Specify the validation error messages
      messages: {
        name: "Name is required",
        email: "Enter Valid Email ID",
        message: "Message is required",
      },
      submitHandler: function (form) {
        form.submit();
      },
    });
    $("form[name='contact-us-form']").validate({
      rules: {
        name: "required",
        phone: "required",
        email: {
          //email is corresponding input name
          required: true,
          email: true,
        },
        message: "required",
      },
      // Specify the validation error messages
      messages: {
        name: "Full Name is required",
        email: "Enter Valid Email ID",
        phone: "Phone is Required",
        message: "Message is required",
      },
      submitHandler: function (form) {
        form.submit();
      },
    });
  });
});
